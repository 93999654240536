.header_container {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0 2.6rem 0;
}

.header_left_wrapper {
  display: flex;
  flex-direction: column;
}

.header_left_text {
  max-width: 18rem;
  font-weight: 410;
  font-size: 0.8rem;
  line-height: 1rem;
  margin: 0;
}

.header_logo_wrapper {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.logo_text {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 590;
}

.logo_text span {
  font-stretch: expanded;
  color: #75B4EF;
  font-weight: 410;
}

.header_regions {
  display: none;
}

.mobile_menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile_header_regions {
  position: absolute;
  top: 11rem;
  left: 0rem;
  width: 100%;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  background-color: #1A1919;

  gap: 3.2rem;
  color: #8B8B8B;
  font-weight: 410;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-stretch: expanded;
}

.header_region {
  padding-left: 2rem;
}

@media (min-width: 820px) {
  .header_regions {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    color: #8B8B8B;
    font-weight: 410;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-stretch: expanded;
  }

  .header_left_text {
    max-width: max-content;
  }

  .mobile_menu {
    display: none;
  }

  .mobile_header_regions {
    display: none;
  }
}