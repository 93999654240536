 .main_container {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-areas:
     'cis'
     'america'
     'europe'
     'asia'
     'arab'
     'oceania'
     'africa'
   ;
   gap: 1.3rem;
 }

 .region_container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   border: 1px solid #383838;
   border-radius: 0.5rem;
 }

 .region_container:first-child {
   grid-area: cis;
 }

 .region_container:nth-child(2) {
   grid-area: america;
 }

 .region_container:nth-child(3) {
   grid-area: europe;
 }

 .region_container:nth-child(4) {
   grid-area: asia;
 }

 .region_container:nth-child(5) {
   grid-area: arab;
 }

 .region_container:nth-child(6) {
   grid-area: oceania;
 }

 .region_container:last-child {
   grid-area: africa;
 }

 table {
   min-width: 100%;
   border-collapse: collapse;
 }

 thead tr th {
   text-align: left;
   padding: 1.1rem 1.3rem 0.9rem 1.3rem;
   border-bottom: 1px solid #383838;
   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;
 }

 tbody td {
   padding: 0 0.5rem 0.8rem 1.3rem;
 }

 tbody tr:first-child td {
   padding-top: 1.4rem;
 }

 tbody tr:last-child td {
   padding-bottom: 1.8rem;
 }

 th {
   font-size: 1.2rem;
   line-height: 1.4rem;
   font-weight: 590;
   white-space: nowrap;
 }

 td {
   font-size: 1rem;
   line-height: 1.2rem;
   font-weight: 510;
   white-space: nowrap;
 }

 .region_warning {
   color: #8B8B8B;
   font-size: 0.9rem;
   line-height: 1.1rem;
   font-stretch: expanded;
   padding: 0 0.5rem 0.98rem 1.3rem;
 }

 .region_link {
   color: #B9B9BB;
   font-size: 1rem;
   line-height: 1.2rem;
   font-stretch: expanded;
   padding: 0 0.5rem 0.8rem 1.3rem;
 }

 @media (min-width: 555px) {
   .main_container {
     grid-template-columns: 1fr 1fr;
     grid-template-areas:
       'cis europe'
       'america europe'
       'asia africa'
       'arab africa'
       'arab oceania'
     ;
   }
 }

 @media (min-width: 1000px) {
   .main_container {
     grid-template-columns: 1fr 1fr 1fr;
     grid-template-areas:
       'cis europe africa'
       'america europe africa'
       'asia oceania arab'
     ;
   }

   .region_container:nth-child(6) {
     height: fit-content;
   }
 }

 @media (min-width: 1347px) {
   .main_container {
     grid-template-columns: 1fr 1fr 1fr 1fr;
     grid-template-rows: 25.8rem 1fr;
     grid-template-areas:
       'cis europe asia arab africa'
       'america europe asia arab africa'
       'america europe . oceania africa'
     ;
   }
 }