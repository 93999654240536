*,
*::after,
*::before {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  color: white;
  height: 100vh;
  font-size: 62.5%
}

body {
  position: relative;
  margin: 0;
  background-color: #1A1919;
  font-family: SF pro;
  font-weight: 510;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 4rem);
  padding-bottom: 3rem;
  margin: auto;
}

@media (min-width: 1347px) {
  .container {
    min-height: 100%;
    padding: 0 6.9rem 3rem 6rem;
  }
}